import React from "react"

import { useTranslation } from "react-i18next"
import { LocalizedLink } from "gatsby-theme-i18n"

export const Button = props => {
  const { t } = useTranslation("globals")
  return (
    <LocalizedLink to={props.to}>
      <button className="h-12 w-60 font-montserrat-semi leading-tight tracking-wider bg-black text-white rounded-md hover:border-2 hover:text-black  hover:bg-orange-construction hover:border-orange-construction ">
        {t("button")}
      </button>
    </LocalizedLink>
  )
}

export const ButtonForm = props => {
  return (
    <>
      <button
        type="submit"
        className="h-12 w-52 md:w-60 font-montserrat-semi leading-tight tracking-wider bg-black text-white rounded-md hover:border-2 hover:text-black  hover:bg-orange-construction hover:border-orange-construction "
      >
        {props.text}
      </button>
    </>
  )
}
