import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { useTranslation } from "react-i18next"
import { LocalizedLink } from "gatsby-theme-i18n"

import { Button } from "../elements/button"

const Hero = () => {
  const { t } = useTranslation("globals")

  return (
    <>
      <div className="min-h-screen lg:min-h-full pb-4 flex flex-col">
        <>
          <div className="h-32 md:h-56 bg-orange-construction" />
          <div className="md:hidden flex items-center justify-center -mt-24 pb-2">
            <StaticImage
              height={200}
              src="../../images/logo.png"
              alt="logo"
              placeholder="tracedSVG"
            />
          </div>
          <div className="hidden md:flex items-center justify-center -mt-40 pb-4">
            <StaticImage
              height={320}
              src="../../images/logo.png"
              alt="logo"
              placeholder="tracedSVG"
            />
          </div>
          <div className="h-96 flex flex-col items-center justify-around">
            <LocalizedLink
              to="/services"
              className="my-auto font-montserrat-reg text-gray-concrete lg:text-2xl text-center"
            >
              {t("header")}
            </LocalizedLink>
            <h1 className="my-auto text-center font-inter text-3xl sm:text-5xl xl:text-6xl">
              {t("title1")} <br /> {t("title2")}
            </h1>
            <div className="my-auto mx-auto container w-4/5 md:w-1/2">
              <p className=" font-montserrat-reg text-center text-sm sm:text-md xl:text-lg">
                {t("desc")}
              </p>
            </div>
            <div className="my-auto lg:my-4 flex flex-col items-center space-y-3 sm:space-y-6 lg:space-y-12">
              <Button to="/contact" text={t("button")} />
            </div>
          </div>
        </>
      </div>
    </>
  )
}

export default Hero
