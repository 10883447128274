import React from "react"

import { LocalizedLink } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

import { SpanishFlag, EnglishFlag } from "../../components/elements/Flags"

const Header = props => {
  const { t } = useTranslation("globals")

  return (
    <>
      <div className={`my-4 lg:my-10 ${props.styles}`}>
        <div className="relative lg:fixed top-0 w-full pt-2 lg:p-4  font-montserrat-semi leading-tight md:tracking-normal tracking-tighter text-md md:text-xl z-10 bg-white ">
          <nav className="flex flex-col sm:flex-row items-center justify-between sm:justify-around">
            <div className="flex items-center justify-around w-full sm:justify-around">
              <LocalizedLink to="/services">{t("navlink1")}</LocalizedLink>
              <LocalizedLink to="/projects">{t("navlink2")}</LocalizedLink>
              <LocalizedLink to="/contact">{t("navlink3")}</LocalizedLink>
            </div>

            <div className="flex items-center pt-2 sm:pt-0  gap-2 lg:pr-4">
              <LocalizedLink
                activeClassName="underline"
                className="text-sm"
                to="/"
                language="en"
              >
                EN
              </LocalizedLink>
              <LocalizedLink
                activeClassName="underline"
                className="text-sm"
                to="/"
                language="es"
              >
                ES
              </LocalizedLink>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Header
