import * as React from "react"

import Header from "../components/sections/header"
import Seo from "../components/seo"

import Hero from "../components/sections/hero"

const IndexPage = () => (
  <>
    <Header />
    <Seo title="Home" />
    <Hero />
  </>
)

export default IndexPage
